import { Children } from 'react'
import { motion } from 'framer-motion'
import { useStore3 } from './store3'

const container = {
  hidden: { opacity: 0, height: 0, transition: { staggerChildren: 0.05 } },
  show: {
    opacity: 1,
    height: 'auto',
    transition: { when: 'beforeChildren', staggerChildren: 0.05 }
  }
}

const item = {
  hidden: { opacity: 0, y: '100%' },
  show: { opacity: 1, y: 0 }
}

function List({ children, open }) {
  return (
    <motion.ul variants={container} initial="hidden" animate={open ? 'show' : 'hidden'}>
      {Children.map(children, (child) => (
        <li>
          <motion.div variants={item}>{child}</motion.div>
        </li>
      ))}
    </motion.ul>
  )
}

export function Overlay3() {
  const state = useStore3()
  return (
    <>
      <div className="info">
        <List open={state.open}>
          <h3>What is the release date</h3>
          
          <h3>
            <span className="accent">for HH?</span>
          </h3>
          <h4>TBA!</h4>
          <p>
          Stay updated on social channels<br/>
          and join the discord group<br/>
          so you don't miss all the news<br/>
          about the project!
          </p>
        </List>
      </div>
    </>
  )
}