import { Children } from 'react'
import { motion } from 'framer-motion'
import { useStore1 } from './store1'

const container = {
  hidden: { opacity: 0, height: 0, transition: { staggerChildren: 0.05 } },
  show: {
    opacity: 1,
    height: 'auto',
    transition: { when: 'beforeChildren', staggerChildren: 0.05 }
  }
}

const item = {
  hidden: { opacity: 0, y: '100%' },
  show: { opacity: 1, y: 0 }
}

function List({ children, open }) {
  return (
    <motion.ul variants={container} initial="hidden" animate={open ? 'show' : 'hidden'}>
      {Children.map(children, (child) => (
        <li>
          <motion.div variants={item}>{child}</motion.div>
        </li>
      ))}
    </motion.ul>
  )
}

export function Overlay1() {
  const state = useStore1()
  return (
    <>
      <div className="info">
        <List open={state.open}>
          <h3>WHAT IS</h3>
          <h3>
            <span className="accent">HH?</span>
          </h3>
          <h4>Hidden Heads</h4>
          <p>
          Hidden Heads is a collection of 3333 unique<br/>
          pieces designed on the Ethereum network.<br/>
          Hidden Heads however is much more than that,<br/>
          our goal is to create a work platform that<br/>
          can be used by anyone<br/>
          in need of professional growth.<br/>
          For more information check out our medium<br/>
          by clicking in the section below.
          </p>
        </List>
      </div>
    </>
  )
}