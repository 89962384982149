import { useFrame, useThree, Canvas, extend } from '@react-three/fiber'
import { Box, Flex} from '@react-three/flex'
import React, { useRef, useState, Suspense, useEffect, useMemo } from 'react'
import * as THREE from 'three'
import { MathUtils } from 'three'
import styles from './styles.css'
import { useGLTF, Text3D, Scroll, Html, OrbitControls, useScroll, ScrollControls, Text, PerspectiveCamera, useVideoTexture, useTexture, Float, Center, MeshDiscardMaterial } from '@react-three/drei'
import { easing, geometry } from 'maath'
import { useStore1 } from './store1'
import { useStore2 } from './store2'
import { useStore3 } from './store3'
import { useStore4 } from './store4'

extend(geometry)


function Scene() {
  const { height, width } = useThree((state) => state.viewport);
  const [scrollPages, setScrollPages] = useState(1);
  const handleReflow = (_, flexHeight) => setScrollPages(Math.ceil(flexHeight / height));
  const ref = useRef();
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      handleReflow();
    }, 100);
    return () => clearTimeout(timeoutId);
  }, []);
  return (
    <ScrollControls damping={0.05} distance={1} pages={scrollPages-1.75}>
      <Scroll>
        <Flex
          size={[width, height, 0]}
          position={[-width / 2, height / 2, 0]}
          flexDirection="row"
          justifyContent="center"
          wrap="wrap"
          onReflow={handleReflow}>
          <Box centerAnchor marginBottom={1}>
           <SectionHero />
          </Box>
          <Box centerAnchor marginTop={0.5} marginBottom={0-5}>
           <Testo />
          </Box>
          <Box centerAnchor margin={1} marginLeft={10} marginRight={10}>
            <Mint/>
          </Box>
          <Box centerAnchor marginTop={0.5}>
            <Section1 position={[0, 0, 0]} />
          </Box>
          {/* <Box centerAnchor marginTop={0.5}>
            <Section2 position={[0, 0, 1]} />
          </Box> */}
          <Box centerAnchor marginTop={0.5}>
            <Section2 scale={0.862} position={[0, 0, 0]} />
          </Box>
          <Box centerAnchor marginTop={1.1} marginLeft={100} marginRight={100}>
          </Box>
          {/* <Box centerAnchor margin={0}>
            <Section3 position={[0, 0, 0.8]} />
          </Box> */}
          <Box centerAnchor margin={0}>
            <Section3 position={[0, 0, 0]} />
          </Box>
          <Box centerAnchor margin={0}>
            <Section4 position={[0, 0, 0]} />
          </Box>
          {/* <Box centerAnchor margin={0}>
            <Carousel position={[0, 0, 1]} />
          </Box> */}
          <Box centerAnchor margin={0}>
            <Carousel position={[0, 0, 0]} />
          </Box>
          <Box centerAnchor margin={0.5} marginLeft={10} marginRight={10}>
            <Faq/>
          </Box>
          <Box centerAnchor margin={1} marginLeft={10} marginRight={10}>
            <Testo2/>
          </Box>
          {/* <Box centerAnchor margin={0.2}>
            <Opensea/>
          </Box> */}
          {/* <Box centerAnchor margin={0.2} >
            <Insta/>
          </Box> */}
          {/* <Box centerAnchor margin={0.2} >
            marketplace2
            <Twitter/>
          </Box> */}
          <Box centerAnchor margin={0.2} >
            <Twitter/>
          </Box>
          <Box centerAnchor margin={0.2} >
            <Mail/>
          </Box>
          <Box centerAnchor margin={0.2}>
            <Medium/>
          </Box>
          <Box centerAnchor margin={1} marginLeft={10} marginRight={10}>
            <Testo3/>
          </Box>
          <Box centerAnchor margin={0}>
            <Section9 position={[0, 0, 0]} />
          </Box>
          <Box centerAnchor margin={0}>
            <Section10 position={[0, 0, 0]} />
          </Box>
          <Box centerAnchor margin={0} marginTop={0}>
            <Section11 position={[0, 0, 0]} />
          </Box>
          {/* <Box centerAnchor margin={0} marginTop={0}>
            <Section12 position={[0, 0, 0]} />
          </Box> */}
          <Box centerAnchor marginTop={0} margin={1} marginLeft={10} marginRight={10}>
            <Section13/>
          </Box>
        </Flex>
      </Scroll>
    </ScrollControls>
  )
}



function SectionHeroMaterial({ url }) {
  const texture = useVideoTexture(url)
  // texture.magFilter = THREE.NearestFilter
  // texture.colorSpace = THREE.SRGBColorSpace
  return <meshBasicMaterial map={texture} toneMapped={false} />
}
function FallbackMaterial({ url }) {
  const texture = useTexture(url)
  return <meshBasicMaterial map={texture} toneMapped={false} />
}
function isMobile() {
  if (typeof window !== 'undefined') {
    return /Mobi|Android/i.test(window.navigator.userAgent);
  }
  return false;
}

function SectionHero(props) {
  const isMobileDevice = isMobile();
  
  const { height, width } = useThree((state) => state.viewport);
  const scroll = useScroll();
  const ref = useRef();
  
  useFrame(() => (ref.current.position.y = scroll.offset * 10));

  return (
    <group {...props} dispose={null}>
      <group ref={ref}>
        <mesh>
          <boxGeometry args={[width * 1.500, width * 0.350, 0]} />
          <Suspense fallback={<FallbackMaterial url="hero.png" />}>
            { isMobileDevice 
              ? <FallbackMaterial url="hero.png" />
              : <SectionHeroMaterial url="1.mp4" />
            }
          </Suspense>
        </mesh>
      </group>
    </group>
  );
}

function Testo({ x = 0, y = 1.2, ...props }) {
  const { size: { width, height } } = useThree()
  // const { height, width } = useThree((state) => state.viewport)
  const ratio = height / width
  const fov = Math.min(1.2, ratio)
  const fov2 = Math.min(0.8, ratio)
  const scroll = useScroll()
  const ref = useRef()
  useFrame(() => (ref.current.position.y = scroll.offset * 10))
  const textRef = useRef()
  const rand = 0
  useFrame((state) => (textRef.current.position.x = x + x + Math.sin(rand - state.clock.elapsedTime / 8) * 4))
  return (
    <group ref={ref}>
      <group position={[-20, 0, 0]}>
        <Text3D
          scale={[0.17/fov2, 0.17/fov2, 0]}
          ref={textRef}
          position={[x, 1.8, -0.1]}
          font='./1.json'>
        AS EVERYTHING CHANGES, WILL YOU STAND BY AND WATCH?     AS EVERYTHING CHANGES, WILL YOU STAND BY AND WATCH?     AS EVERYTHING CHANGES, WILL YOU STAND BY AND WATCH?
          <meshBasicMaterial color={'grey'} toneMapped={false} />
        </Text3D>
      </group>
      <mesh position={[0, 2.4, -0.2]}>
        <boxGeometry args={[20/fov2, 1/fov2, 0]} />
        <meshBasicMaterial color={'white'} toneMapped={false} />
      </mesh>
    </group>
  )
}





// function Mint() {
//   const [hovered1, hover1] = useState(false)
//   const { size: { width, height } } = useThree()
//   const ratio = height / width
//   const fov = Math.min(1, ratio)
//   const scroll = useScroll()
//   const ref = useRef()
//   useFrame(() => {
//     ref.current.position.y = scroll.offset * 10
//     ref.current.position.z = hovered1
//       ? MathUtils.lerp(ref.current.position.z, -1, 0.40)
//       : MathUtils.lerp(ref.current.position.z, 0, 0.40)
//   })  
//   return (
//     <group ref={ref}>
//       <mesh
//         onPointerOver={() => {hover1(true)}}
//         onPointerOut={() => {hover1(false)}}
//         onClick={(e) => window.open('https://google.com')}>
//         <boxGeometry args={[3/fov, 1/fov, 0.001]}/>
//         <meshPhysicalMaterial roughness={0} metalness={0} thickness={3.12} ior={1.74} transmission={1.0} />
//       </mesh>
//       <Center >
//       <Text3D
//         scale={[0.3/fov, 0.3/fov, 0.001]}
//         onClick={(e) => window.open('https://google.com')}
//         position={[-1, 0, -0.2]}
//         font='./1.json'
//         anchorX="center"
//         anchorY="middle">
//           Mint
//         <meshBasicMaterial color={'white'} toneMapped={false} />
//       </Text3D>
//       </Center>
//     </group>
//   )
// }




function MintMaterial({ url }) {
  const texture = useTexture(url)
  return <meshBasicMaterial map={texture} toneMapped={false} alphaTest={0.1} />
}
function Mint() {
  const [hovered1, hover1] = useState(false)
  const { size: { width, height } } = useThree()
  const ratio = height / width
  const fov = Math.min(1, ratio)
  const scroll = useScroll()
  const ref = useRef()
  useFrame(() => {
    ref.current.position.y = scroll.offset * 10
    ref.current.position.z = hovered1
      ? MathUtils.lerp(ref.current.position.z, -1, 0.40)
      : MathUtils.lerp(ref.current.position.z, 0, 0.40)
  })  
  return (
    <group ref={ref}>
      <mesh
        onPointerOver={() => {hover1(true)}}
        onPointerOut={() => {hover1(false)}}
        onClick={(e) => window.open('https://google.com')}>
        <boxGeometry args={[2/fov, 1/fov, 0]}/>
        <MintMaterial url="mint.png" />
      </mesh>
    </group>
  )
}



function Section1Material({ url }) {
  const texture = useTexture(url)
  return <meshBasicMaterial map={texture} toneMapped={false} alphaTest={0} />
}
function Section1(props) {
  const { size: { width, height } } = useThree()
  const ratio = height / width
  const fov = Math.min(0.7, ratio)
  const scroll = useScroll()
  const ref = useRef()
  useFrame(() => (ref.current.position.y = scroll.offset * 10))
  return (
    <group {...props} dispose={null}>
      <group ref={ref}>
        <mesh scale={[3/fov, 3/fov, 0]}>
          <planeGeometry />
          <Section1Material url="1.png" />
        </mesh>
      </group>
    </group>
  )
}



function Section2Material({ url }) {
  const texture = useVideoTexture(url)
  // texture.magFilter = THREE.NearestFilter
  // texture.colorSpace = THREE.SRGBColorSpace
  return <meshBasicMaterial map={texture} toneMapped={false} />
}
function Section2(props) {
  const { height, width } = useThree((state) => state.viewport)
  const scroll = useScroll()
  const ref = useRef()
  const ratio = height / width
  const fov = Math.min(0.7, ratio)
  useFrame(() => (ref.current.position.y = scroll.offset * 10))
  return (
    <group {...props} dispose={null}>
      <group ref={ref}>
        <mesh scale={[3/fov, 3/fov, 0]}>
          <planeGeometry />
            <Section2Material url="2.mp4" />
        </mesh>
      </group>
    </group>
  )
}


function Section2oMaterial({ url }) {
  const texture = useTexture(url)
  return <meshBasicMaterial map={texture} toneMapped={false} alphaTest={0.9} />
}
function Section2o(props) {
  const { size: { width, height } } = useThree()
  const ratio = height / width
  const fov = Math.min(0.7, ratio)
  const scroll = useScroll()
  const ref = useRef()
  useFrame(() => (ref.current.position.y = scroll.offset * 10))
  return (
    <group {...props} dispose={null}>
      <group ref={ref}>
        <mesh scale={[3/fov, 3/fov, 0]}>
          <planeGeometry />
          <Section2oMaterial url="2.png" />
        </mesh>
      </group>
    </group>
  )
}


function Section3Material({ url }) {
  const texture = useTexture(url)
  return <meshBasicMaterial map={texture} toneMapped={false} alphaTest={0.9} />
}
function Section3(props) {
  const { size: { width, height } } = useThree()
  const ratio = height / width
  const fov = Math.min(0.7, ratio)
  const scroll = useScroll()
  const ref = useRef()
  useFrame(() => (ref.current.position.y = scroll.offset * 10))
  return (
    <group {...props} dispose={null}>
      <group ref={ref}>
        <mesh scale={[3/fov, 3/fov, 0]}>
          <planeGeometry />
          <Section3Material url="3.png" />
        </mesh>
      </group>
    </group>
  )
}


function Section4Material({ url }) {
  const texture = useTexture(url)
  return <meshBasicMaterial map={texture} toneMapped={false} alphaTest={0.9} />
}
function Section4(props) {
  const { size: { width, height } } = useThree()
  const ratio = height / width
  const fov = Math.min(0.7, ratio)
  const scroll = useScroll()
  const ref = useRef()
  useFrame(() => (ref.current.position.y = scroll.offset * 10))
  return (
    <group {...props} dispose={null}>
      <group ref={ref}>
        <mesh scale={[3/fov, 3/fov, 0]}>
          <planeGeometry />
          <Section4Material url="4.png" />
        </mesh>
      </group>
    </group>
  )
}

function CarouselMaterial({ url }) {
  const texture = useTexture(url)
  return <meshBasicMaterial map={texture} toneMapped={false} alphaTest={0.9} />
}
function Carousel({ x = 0, y = -1, ...props }) {
  const { size: { width, height } } = useThree()
  const ratio = height / width
  const fov = Math.min(0.7, ratio)
  const scroll = useScroll()
  const ref = useRef()
  useFrame(() => (ref.current.position.y = scroll.offset * 10))
  const textRef = useRef()
  const rand = 0
  useFrame((state) => (textRef.current.position.x = x + Math.sin(rand - state.clock.elapsedTime / 7) * 7.5))
  return (
        <group {...props} dispose={null}>
        <group ref={ref}>
          <mesh scale={[10*3, 1.5*3, 0]} ref={textRef} position={[x, y, 0]}>
            <planeGeometry />
            <CarouselMaterial url="carousel.png" />
          </mesh>
        </group>
      </group>
  )
}


function Faq() {
  const [hovered1, hover1] = useState(false)
  const [hovered2, hover2] = useState(false)
  const [hovered3, hover3] = useState(false)
  const [hovered4, hover4] = useState(false)
  useFrame(() => (ref.current.position.y = scroll.offset * 10))
  const { size: { width, height } } = useThree()
  const ratio = height / width
  const fov = Math.min(0.5, ratio)
  const scroll = useScroll()
  const ref = useRef()
  useFrame(() => (ref.current.position.y = scroll.offset * 10))
  return (
    <group ref={ref}>
      <Text
      position={[0, 2, 0]}
        scale={[4/fov, 4/fov, 0.01]}
        fontSize={0.1}
        color="grey"
        font='./1.ttf'>
      {'FAQ'}
      </Text>
      <Selector1>
        <Text
        position={[0, 1, 0]}
          scale={[2/fov, 2/fov, 0.01]}
          fontSize={0.07}
          onPointerOver={(event) => hover1(true)}
          onPointerOut={(event) => hover1(false)}
          color={hovered1 ? '#dabdab' : 'grey'}   
          font='./2.ttf'>
        'What is HH?'
        </Text>
      </Selector1>
      <Selector2>
        <Text
        position={[0, 0, 0]}
          scale={[2/fov, 2/fov, 0.01]}
          fontSize={0.07}
          onPointerOver={(event) => hover2(true)}
          onPointerOut={(event) => hover2(false)}
          color={hovered2 ? '#dabdab' : 'grey'}  
          font='./2.ttf'>
        'Who is behind HH?'
        </Text>
      </Selector2>
      <Selector3>
      <Text
      position={[0, -1, 0]}
        scale={[2/fov, 2/fov, 0.01]}
        fontSize={0.07}
        onPointerOver={(event) => hover3(true)}
        onPointerOut={(event) => hover3(false)}
        color={hovered3 ? '#dabdab' : 'grey'} 
        font='./2.ttf'>
        'What is the release date for HH?'
        </Text>
      </Selector3>
      <Selector4>
      <Text
      position={[0, -2, 0]}
        scale={[1.9/fov, 1.9/fov, 0.01]}
        fontSize={0.07}
        onPointerOver={(event) => hover4(true)}
        onPointerOut={(event) => hover4(false)}
        color={hovered4 ? '#dabdab' : 'grey'} 
        font='./2.ttf'>
        'What benefits do I get in owning a HH?'
        </Text>
      </Selector4>
    </group>
  )
}


function Testo2() {
  const { size: { width, height } } = useThree()
  const ratio = height / width
  const fov = Math.min(0.5, ratio)
  const scroll = useScroll()
  const ref = useRef()
  useFrame(() => (ref.current.position.y = scroll.offset * 10))
  return (
    <group ref={ref}>
      <Text
        scale={[2.5/fov, 2.5/fov, 0.01]}
        fontSize={0.07}
        color="grey"
        font='./1.ttf'>
      {'JOIN THE HIDDENHEADS\n           COMMUNITY!'}
      </Text>
    </group>
  )
}


export function Opensea(props) {
  const [hovered1, hover1] = useState(false)
  const { nodes, materials } = useGLTF("/opensea.gltf");
  const { size: { width, height } } = useThree()
  const ratio = height / width
  const fov = Math.min(1, ratio)
  const scroll = useScroll()
  const ref = useRef()
  useFrame(() => (ref.current.position.y = scroll.offset * 10))
  return (
    <group {...props} ref={ref} dispose={null}>
      <Float
        speed={1}
        rotationIntensity={1}
        floatIntensity={1} 
        floatingRange={[-0.2, 0.2]}> 
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve001.geometry}
          position={[0.023, 0.043, -0.006]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={2.76}
          onClick={(e) => window.open('https://opensea.io')}
          onPointerOver={(event) => hover1(true)}
          onPointerOut={(event) => hover1(false)}>
            <meshBasicMaterial color={hovered1 ? 'white' : '#56a7d6'} toneMapped={false} />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve002.geometry}
          material={nodes.Curve002.material}
          position={[0.023, 0.043, -0.007]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={2.4}
          onClick={(e) => window.open('https://opensea.io')}
          onPointerOver={(event) => hover1(true)}
          onPointerOut={(event) => hover1(false)}>
            <meshBasicMaterial color={hovered1 ? 'white' : '#56a7d6'} toneMapped={false} />
        </mesh>
      </Float>
    </group>
  );
}
useGLTF.preload("/opensea.gltf");




function Twitter({ props }) {
  const [hovered1, hover1] = useState(false)
  const { nodes } = useGLTF("/x.gltf");
  const { size: { width, height } } = useThree()
  const ratio = height / width
  const fov = Math.min(1, ratio)
  const scroll = useScroll()
  const ref = useRef()
  useFrame(() => (ref.current.position.y = scroll.offset * 10))
  return (
    <group {...props} dispose={null}>
      <group ref={ref} position={[0, 0, 0]}>
        <Float
          speed={1}
          rotationIntensity={1}
          floatIntensity={1} 
          floatingRange={[-0.2, 0.2]}> 
          <mesh
            name="button"
            castShadow
            receiveShadow
            geometry={nodes.Torus.geometry}
            scale={[0.5, 0.5, 0.5]}
            rotation={[Math.PI / 2, 0, 0]}>
              <meshBasicMaterial color={hovered1 ? 'white' : 'grey'} toneMapped={false} />
          </mesh>
          <mesh
            onClick={(e) => window.open('https://twitter.com/HiddenHeadsNFT')}
            onPointerOver={(event) => hover1(true)}
            onPointerOut={(event) => hover1(false)}>
            <boxGeometry args={[1.2, 1.2, 0.1]} />
            <MeshDiscardMaterial />
        </mesh>
        </Float>
      </group>
    </group>
  );
}
useGLTF.preload("/x.gltf");


export function Mail(props) {
  const [showModal, setShowModal] = useState(false);
  const [hovered1, hover1] = useState(false)
  const { nodes } = useGLTF("/mail.gltf");
  const { size: { width, height } } = useThree()
  const ratio = height / width
  const fov = Math.min(1, ratio)
  const scroll = useScroll()
  const ref = useRef()
  useFrame(() => (ref.current.position.y = scroll.offset * 10))
  const meshRef = useRef();
  useEffect(() => {
    const mesh = meshRef.current;
    if (mesh) {
      mesh.addEventListener('click', handleMeshClick);
      return () => {
        mesh.removeEventListener('click', handleMeshClick);
      };
    }
  }, []);
  const handleMeshClick = () => {
    const email = "Mikenshall@gmail.com";
    const subject = "Oggetto della mail";
    const body = "Corpo del messaggio";
    window.location.href = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  };
  return (
    <group {...props} dispose={null}>
      <group ref={ref} position={[0, 0, 0]}>
        <Float
          speed={1}
          rotationIntensity={1}
          floatIntensity={1} 
          floatingRange={[-0.2, 0.2]}> 
            <group scale={5}>
            <mesh
              ref={meshRef}
              castShadow
              receiveShadow
              geometry={nodes.Gmail_1.geometry}
              position={[0, -0.02, 0]}
              rotation={[Math.PI / 2, 0, 0]}
              scale={0.01}
              onClick={handleMeshClick}
              onPointerOver={(event) => hover1(true)}
              onPointerOut={(event) => hover1(false)}>
                <meshBasicMaterial color={'grey'} toneMapped={false} />
            </mesh>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Plane.geometry}
              position={[0, 0, 0.019]}
              rotation={[Math.PI / 2, 0, 0]}
              scale={0.097}
              onClick={handleMeshClick}>
                <meshBasicMaterial color={hovered1 ? 'red' : '#fff6e0'} toneMapped={false} />
            </mesh>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Plane001.geometry}
              position={[0, 0, 0.012]}
              rotation={[Math.PI / 2, 0, 0]}
              scale={0.072}
              onClick={(e) => window.open('https://mail.google.com/')}>
                <meshBasicMaterial color={'#fff6e0'} toneMapped={false} />
            </mesh>
          </group>
        </Float>
      </group>
    </group>
  );
}
useGLTF.preload("/mail.gltf");



export function Insta(props) {
  const [hovered1, hover1] = useState(false)
  const { nodes, materials } = useGLTF("/insta.gltf");
  const { size: { width, height } } = useThree()
  const ratio = height / width
  const fov = Math.min(1, ratio)
  const scroll = useScroll()
  const ref = useRef()
  useFrame(() => (ref.current.position.y = scroll.offset * 10))
  return (
    <group {...props} ref={ref} dispose={null} scale={0.25}>
      <Float
        speed={1}
        rotationIntensity={1}
        floatIntensity={1} 
        floatingRange={[-0.2, 0.2]}> 
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.ig1.geometry}
          position={[0.019, 0.167, 0]}
          scale={[1.776, 1.776, 0.0001]}
          onClick={(e) => window.open('https://www.instagram.com/')}
          onPointerOver={(event) => hover1(true)}
          onPointerOut={(event) => hover1(false)}>
            <meshBasicMaterial color={hovered1 ? 'white' : '#ff54e3'} toneMapped={false} />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.ig2.geometry}
          position={[0.019, 0.167, 0]}
          scale={[1.26, 1.26, 0.0001]}
          onClick={(e) => window.open('https://www.instagram.com/')}
          onPointerOver={(event) => hover1(true)}
          onPointerOut={(event) => hover1(false)}>
            <meshBasicMaterial color={hovered1 ? 'white' : '#ff54e3'} toneMapped={false} />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.ig3.geometry}
          position={[0, 0.055, 0]}
          rotation={[-Math.PI / 2, -0.363, 0]}
          scale={[0.585, 0.0001, 0.585]}
          onClick={(e) => window.open('https://www.instagram.com/')}
          onPointerOver={(event) => hover1(true)}
          onPointerOut={(event) => hover1(false)}>
            <meshBasicMaterial color={hovered1 ? 'white' : '#ff54e3'} toneMapped={false} />
        </mesh>
      </Float>
    </group>
  );
}
useGLTF.preload("/insta.gltf");


export function Medium(props) {
  const { nodes, materials } = useGLTF("/medium.gltf");
  const [hovered1, hover1] = useState(false)
  const { size: { width, height } } = useThree()
  const ratio = height / width
  const fov = Math.min(1, ratio)
  const scroll = useScroll()
  const ref = useRef()
  useFrame(() => (ref.current.position.y = scroll.offset * 10))
  return (
    <group {...props} ref={ref} dispose={null} scale={0.5}>
      <Float
        speed={1}
        rotationIntensity={1}
        floatIntensity={1} 
        floatingRange={[-0.2, 0.2]}> 
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Sphere.geometry}
          position={[0.259, 0, 0]}
          scale={[0.251, 0.547, 0.0001]}>
            <meshBasicMaterial color={hovered1 ? 'white' : 'grey'} toneMapped={false} />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Sphere001.geometry}
          position={[-0.626, 0, 0]}
          scale={[0.58, 0.58, 0.0001 ]}>
          <meshBasicMaterial color={hovered1 ? 'white' : 'grey'} toneMapped={false} />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Sphere002.geometry}
          position={[0.645, 0, 0]}
          scale={[0.094, 0.517, 0.0001]}>
            <meshBasicMaterial color={hovered1 ? 'white' : 'grey'} toneMapped={false} />
        </mesh>
        <mesh
          onClick={(e) => window.open('https://medium.com/@HIDDENHEADSMR/hiddenheads-the-beginning-8f6e9fe7aed7')}
          onPointerOver={(event) => hover1(true)}
          onPointerOut={(event) => hover1(false)}>
            <boxGeometry args={[2.5, 1.5, 0.1]} />
            <MeshDiscardMaterial />
        </mesh>
      </Float>
    </group>
  );
}
useGLTF.preload("/medium.gltf");


function Testo3() {
  const { size: { width, height } } = useThree()
  const ratio = height / width
  const fov = Math.min(0.5, ratio)
  const scroll = useScroll()
  const ref = useRef()
  useFrame(() => (ref.current.position.y = scroll.offset * 10))
  return (
    <group ref={ref}>
      <Text
        scale={[4/fov, 4/fov, 0]}
        fontSize={0.07}
        color="grey"
        font='./1.ttf'>
      MEET THE TEAM!
      </Text>
    </group>
  )
}




function Section9Material({ url }) {
  const texture = useTexture(url)
  return <meshBasicMaterial map={texture} toneMapped={false} alphaTest={0.9} />
}
function Section9(props) {
  const [hovered1, hover1] = useState(false)
  const { size: { width, height } } = useThree()
  const ratio = height / width
  const fov = Math.min(0.7, ratio)
  const scroll = useScroll()
  const ref = useRef()
  useFrame(() => {
    ref.current.position.y = scroll.offset * 10
    ref.current.position.z = hovered1
      ? MathUtils.lerp(ref.current.position.z, -0.5, 0.40)
      : MathUtils.lerp(ref.current.position.z, 0, 0.40)
  })
  return (
    <group {...props} dispose={null}>
      <group ref={ref}>
        <mesh
          scale={[1.5/fov, 1.5/fov, 0.01]}
          onClick={(e) => window.open('https://twitter.com/Matjbog')}
          onPointerOver={(event) => hover1(true)}
          onPointerOut={(event) => hover1(false)}
          position={[0, 0, 0]}>
            <planeGeometry />
            <Section9Material url="9.png" />
        </mesh>
      </group>
    </group>
  )
}


function Section10Material({ url }) {
  const texture = useTexture(url)
  return <meshBasicMaterial map={texture} toneMapped={false} alphaTest={0.9} />
}
function Section10(props) {
  const [hovered1, hover1] = useState(false)
  const { size: { width, height } } = useThree()
  const ratio = height / width
  const fov = Math.min(0.7, ratio)
  const scroll = useScroll()
  const ref = useRef()
  useFrame(() => {
    ref.current.position.y = scroll.offset * 10
    ref.current.position.z = hovered1
      ? MathUtils.lerp(ref.current.position.z, -0.5, 0.40)
      : MathUtils.lerp(ref.current.position.z, 0, 0.40)
  })
  return (
    <group {...props} dispose={null}>
      <group ref={ref}>
        <mesh
          scale={[1.5/fov, 1.5/fov, 0.01]}
          onClick={(e) => window.open('https://twitter.com/MRHHEAD')}
          onPointerOver={(event) => hover1(true)}
          onPointerOut={(event) => hover1(false)}
          position={[0, 0, 0]}>
            <planeGeometry />
            <Section10Material url="10.png" />
        </mesh>
      </group>
    </group>
  )
}



function Section11Material({ url }) {
  const texture = useTexture(url)
  return <meshBasicMaterial map={texture} toneMapped={false} alphaTest={0.9} />
}
function Section11(props) {
  const [hovered1, hover1] = useState(false)
  const { size: { width, height } } = useThree()
  const ratio = height / width
  const fov = Math.min(0.7, ratio)
  const scroll = useScroll()
  const ref = useRef()
  useFrame(() => {
    ref.current.position.y = scroll.offset * 10
    ref.current.position.z = hovered1
      ? MathUtils.lerp(ref.current.position.z, -0.5, 0.40)
      : MathUtils.lerp(ref.current.position.z, 0, 0.40)
  })
  return (
    <group {...props} dispose={null}>
      <group ref={ref}>
      <mesh
        scale={[1.5/fov, 1.5/fov, 0.01]}
        onClick={(e) => window.open('https://linktr.ee/The.Silentroom')}
        onPointerOver={(event) => hover1(true)}
        onPointerOut={(event) => hover1(false)}
        position={[0, 0, 0]}>
          <planeGeometry />
          <Section11Material url="11.png" />
        </mesh>
      </group>
    </group>
  )
}


function Section12Material({ url }) {
  const texture = useTexture(url)
  return <meshBasicMaterial map={texture} toneMapped={false} alphaTest={0.9} />
}
function Section12(props) {
  const [hovered1, hover1] = useState(false)
  const { size: { width, height } } = useThree()
  const ratio = height / width
  const fov = Math.min(0.7, ratio)
  const scroll = useScroll()
  const ref = useRef()
  useFrame(() => {
    ref.current.position.y = scroll.offset * 10
    ref.current.position.z = hovered1
      ? MathUtils.lerp(ref.current.position.z, -0.5, 0.40)
      : MathUtils.lerp(ref.current.position.z, 0, 0.40)
  })
  return (
    <group {...props} dispose={null}>
      <group ref={ref}>
        <mesh
          scale={[1.5/fov, 1.5/fov, 0.01]}
          onClick={(e) => window.open('https://google.com')}
          onPointerOver={(event) => hover1(true)}
          onPointerOut={(event) => hover1(false)}
          position={[0, 0, 0]}>
            <planeGeometry />
            <Section12Material url="12.png" />
        </mesh>
      </group>
    </group>
  )
}


function Section13Material({ url }) {
  const texture = useTexture(url)
  return <meshBasicMaterial map={texture} toneMapped={false} alphaTest={0.9} />
}
function Section13(props) {
  const [hovered1, hover1] = useState(false)
  const { size: { width, height } } = useThree()
  const ratio = height / width
  const fov = Math.min(0.7, ratio)
  const scroll = useScroll()
  const ref = useRef()
  useFrame(() => {
    ref.current.position.y = scroll.offset * 10
    ref.current.position.z = hovered1
      ? MathUtils.lerp(ref.current.position.z, -0.5, 0.40)
      : MathUtils.lerp(ref.current.position.z, 0, 0.40)
  })
  return (
    <group {...props} dispose={null}>
      <group ref={ref}>
        <mesh
          scale={[1.5/fov, 1.5/fov, 0.01]}
          onClick={(e) => window.open('https://www.instagram.com/nit.i.do/')}
          onPointerOver={(event) => hover1(true)}
          onPointerOut={(event) => hover1(false)}
          position={[0, 0, 0]}>
            <planeGeometry />
            <Section13Material url="13.png" />
        </mesh>
      </group>
    </group>
  )
}


// function Allcamera() {
//   const { size: { width, height } } = useThree()
//   const ratio = height / width
//   const fov = Math.min(0.6, ratio)
//     return (
//       <group
//         fov={ [ fov - 45 ] }>
//         <PerspectiveCamera
//         makeDefault={true}
//         far={10000}
//         near={0.1}
//         fov={ fov *100 }
//         position= {[0, 0, 10]} />
//       </group>
//     )
//   }



function Selector1Material({ url }) {
  const texture = useTexture(url)
  return <meshBasicMaterial map={texture} toneMapped={false} alphaTest={0.9} />
}
function Selector1({ children }) {
  const ref = useRef()
  const store1 = useStore1()
  useFrame(({ viewport, camera, pointer }, delta) => {
    const { width, height } = viewport.getCurrentViewport(camera, [0, 0, 3])
    easing.damp3(ref.current.position, [(pointer.x * width) / 2, (pointer.y * height) / 2, 3], store1.open ? 0 : 0.1, delta)
    easing.damp3(ref.current.scale, store1.open ? 400 : 0.01, store1.open ? 0.5 : 0.2, delta)
    easing.dampC(ref.current.material.color, store1.open ? 'white' : '#ffffff', 0.1, delta)
  })
  return (
    <>
      <mesh ref={ref} onClick={() => (store1.open = false)}>
        <circleGeometry args={[0.1, 64, 64]} />
        <Selector1Material url="faq1.png" />
      </mesh>
      <group
        onPointerDown={() => (store1.open = true)}
        onPointerUp={() => (store1.open = false)}>
        {children}
      </group>
    </>
  )
}
function Selector2Material({ url }) {
  const texture = useTexture(url)
  return <meshBasicMaterial map={texture} toneMapped={false} alphaTest={0.9} />
}
function Selector2({ children }) {
  const ref = useRef()
  const store2 = useStore2()
  useFrame(({ viewport, camera, pointer }, delta) => {
    const { width, height } = viewport.getCurrentViewport(camera, [0, 0, 3])
    easing.damp3(ref.current.position, [(pointer.x * width) / 2, (pointer.y * height) / 2, 3], store2.open ? 0 : 0.1, delta)
    easing.damp3(ref.current.scale, store2.open ? 400 : 0.01, store2.open ? 0.5 : 0.2, delta)
    easing.dampC(ref.current.material.color, store2.open ? 'white' : '#ffffff', 0.1, delta)
  })
  return (
    <>
      <mesh ref={ref} onClick={() => (store2.open = false)}>
        <circleGeometry args={[0.1, 64, 64]} />
        <Selector2Material url="faq2.png" />
      </mesh>
      <group
        onPointerDown={() => (store2.open = true)}
        onPointerUp={() => (store2.open = false)}>
        {children}
      </group>
    </>
  )
}
function Selector3Material({ url }) {
  const texture = useTexture(url)
  return <meshBasicMaterial map={texture} toneMapped={false} alphaTest={0.9} />
}
function Selector3({ children }) {
  const ref = useRef()
  const store3 = useStore3()
  useFrame(({ viewport, camera, pointer }, delta) => {
    const { width, height } = viewport.getCurrentViewport(camera, [0, 0, 3])
    easing.damp3(ref.current.position, [(pointer.x * width) / 2, (pointer.y * height) / 2, 3], store3.open ? 0 : 0.1, delta)
    easing.damp3(ref.current.scale, store3.open ? 400 : 0.01, store3.open ? 0.5 : 0.2, delta)
    easing.dampC(ref.current.material.color, store3.open ? 'white' : '#ffffff', 0.1, delta)
  })
  return (
    <>
      <mesh ref={ref} onClick={() => (store3.open = false)}>
        <circleGeometry args={[0.1, 64, 64]} />
        <Selector3Material url="faq3.png" />
      </mesh>
      <group
        onPointerDown={() => (store3.open = true)}
        onPointerUp={() => (store3.open = false)}>
        {children}
      </group>
    </>
  )
}
function Selector4Material({ url }) {
  const texture = useTexture(url)
  return <meshBasicMaterial map={texture} toneMapped={false} alphaTest={0.9} />
}
function Selector4({ children }) {
  const ref = useRef()
  const store4 = useStore4()
  useFrame(({ viewport, camera, pointer }, delta) => {
    const { width, height } = viewport.getCurrentViewport(camera, [0, 0, 3])
    easing.damp3(ref.current.position, [(pointer.x * width) / 2, (pointer.y * height) / 2, 3], store4.open ? 0 : 0.1, delta)
    easing.damp3(ref.current.scale, store4.open ? 4000 : 0.01, store4.open ? 0.5 : 0.2, delta)
    easing.dampC(ref.current.material.color, store4.open ? 'white' : '#ffffff', 0.1, delta)
  })
  return (
    <>
      <mesh ref={ref} onClick={() => (store4.open = false)}>
        <circleGeometry args={[0.1, 64, 64]} />
        <Selector4Material url="faq4.png" />
      </mesh>
      <group
        onPointerDown={() => (store4.open = true)}
        onPointerUp={() => (store4.open = false)}>
        {children}
      </group>
    </>
  )
}

function Swarm({ count, dummy = new THREE.Object3D() }) {
  const mesh = useRef()
  const particles = useMemo(() => {
    const temp = []
    for (let i = 0; i < count; i++) {
      const t = Math.random() * 100
      const factor = 20 + Math.random() * 100
      const speed = 0.0001 + Math.random() / 200
      const xFactor = -50 + Math.random() * 100
      const yFactor = -50 + Math.random() * 100
      const zFactor = -50 + Math.random() * 100
      temp.push({ t, factor, speed, xFactor, yFactor, zFactor, mx: 0, my: 0 })
    }
    return temp
  }, [count])
  useFrame((state) => {
    particles.forEach((particle, i) => {
      let { t, factor, speed, xFactor, yFactor, zFactor } = particle
      t = particle.t += speed / 2
      const a = Math.cos(t) + Math.sin(t * 1) / 10
      const b = Math.sin(t) + Math.cos(t * 2) / 10
      const s = Math.cos(t)
      particle.mx += (state.mouse.x * 1000 - particle.mx) * 1
      particle.my += (state.mouse.y * 1000 - 1 - particle.my) * 1
      dummy.position.set(
        (particle.mx / 10) * a + xFactor + Math.cos((t / 10) * factor) + (Math.sin(t * 1) * factor) / 10,
        (particle.my / 10) * b + yFactor + Math.sin((t / 10) * factor) + (Math.cos(t * 2) * factor) / 10,
        (particle.my / 10) * b + zFactor + Math.cos((t / 10) * factor) + (Math.sin(t * 3) * factor) / 10
      )
      dummy.scale.setScalar(s)
      dummy.rotation.set(s * 5, s * 5, s * 5)
      dummy.updateMatrix()
      mesh.current.setMatrixAt(i, dummy.matrix)
    })
    mesh.current.instanceMatrix.needsUpdate = true
  })
  return (
    <>
      <instancedMesh ref={mesh} args={[null, null, count]}>
        <sphereGeometry args={[0.3, 0]} />
        <meshBasicMaterial color="#dabdab" toneMapped={false} />
      </instancedMesh>
    </>
  )
}

const load = require('./loading.gif');

function Loader({ fadeOut }) {
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
        backgroundColor: '#ffce60',
        opacity: fadeOut ? 0 : 1,
        transition: 'opacity 0s ease-in-out',
      }}
    >
      <img src={load} width="500" height="500" />
    </div>
  );
}



export default function App() {
  const [loading, setLoading] = useState(true);
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    // Initiate fade-out after 4 seconds
    const fadeTimer = setTimeout(() => {
      setFadeOut(true);
    }, 4000);

    // Remove loader after an additional 1 second (to match the 1s transition duration)
    const removeTimer = setTimeout(() => {
      setLoading(false);
    }, 5000);

    return () => {
      clearTimeout(fadeTimer);
      clearTimeout(removeTimer);
    };
  }, []);
  return (
    <>
      {loading && <Loader fadeOut={fadeOut} />}
      <Canvas linear dpr={[1, 2]} className={styles.canvas}>
        {/* <ambientLight /> */}
        {/* <OrbitControls /> */}
        <Suspense fallback={null}>
          <Scene />
        </Suspense>
        <Swarm count={0} />
      </Canvas>
    </>
  );
}