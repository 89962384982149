import ReactDOM from 'react-dom/client'
import './styles.css'
import App from './App'
import { Overlay1 } from './Overlay1'
import { Overlay2 } from './Overlay2'
import { Overlay3 } from './Overlay3'
import { Overlay4 } from './Overlay4'


ReactDOM.createRoot(document.getElementById('root')).render
(
    <>
        <App />
        <Overlay1 />
        <Overlay2 />
        <Overlay3 />
        <Overlay4 />
        <a href="top" style={{ position: 'absolute', bottom: 20, right: 20, fontSize: '13px' }}>
            <img src="logo.png" width="32" height="32"></img>
        </a>
    </>
);