import { Children } from 'react'
import { motion } from 'framer-motion'
import { useStore2 } from './store2'

const container = {
  hidden: { opacity: 0, height: 0, transition: { staggerChildren: 0.05 } },
  show: {
    opacity: 1,
    height: 'auto',
    transition: { when: 'beforeChildren', staggerChildren: 0.05 }
  }
}

const item = {
  hidden: { opacity: 0, y: '100%' },
  show: { opacity: 1, y: 0 }
}

function List({ children, open }) {
  return (
    <motion.ul variants={container} initial="hidden" animate={open ? 'show' : 'hidden'}>
      {Children.map(children, (child) => (
        <li>
          <motion.div variants={item}>{child}</motion.div>
        </li>
      ))}
    </motion.ul>
  )
}

export function Overlay2() {
  const state = useStore2()
  return (
    <>
      <div className="info">
        <List open={state.open}>
          <h3>WHO IS BEHIND</h3>
          
          <h3>
            <span className="accent">HH?</span>
          </h3>
          <h4>made in Italy</h4>
          <p>
          The collection boasts an all-Italian team,<br/>
          each phase of the project was entrusted<br/>
          to a specific team founded in collaboration<br/>
          between the various members of Hidden Heads<br/>
          and the art collective NIT.I.DO.
          </p>
        </List>
      </div>
    </>
  )
}